import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

import enTranslation from '../translations/en.json';

const localResources = {
  en: {
    translation: enTranslation
  }
}

console.log( process.env )
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // resources: localResources,
    backend: {
       loadPath: `${process.env.REACT_APP_BACKEND}:${process.env.REACT_APP_BACKEND_PORT}/translations/{{lng}}`,
    },
    // localeDetection: false,
    detection: {
      order: ["path", "navigator", "localStorage"],
      lookupFromPathIndex: 0,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
