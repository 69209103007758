import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ContentWrapper } from "../style";
import { breakpoint, lightGrey } from "../../constants";

const Wrapper = styled.div`
  background: url("/assets/cta_background.jpg");
  background-size: cover;
`;

const Heading = styled.h1`
  font-size: 52px;
  font-weight: 700;
  line-height: 1.2;
  @media (max-width: ${breakpoint}) {
    font-size: 35px;
  }
`;

const Content = styled.div`
  padding: 100px 0;

  color: ${lightGrey};
  text-align: center;
  @media (max-width: ${breakpoint}) {
    padding: 50px 0;
  }
`;

const Text = styled.div`
  font-size: 42px;
  line-height: 1.3;
  @media (max-width: ${breakpoint}) {
    font-size: 25px;
  }
`;

const SectionWhy = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>{t("main.final.heading")}</Heading>

          <Text>{t("main.final.description")}</Text>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionWhy;
