import styled from "styled-components";
import SectionMain from "./main";
import SectionWhy from "./why";
import SectionFor from "./for";
import SectionTabs from "./tabs";
import { useEffect, useState } from "react";

const Wrapper = styled.div`

`;

const MainPage = () => {
  const [activeTab, setActiveTab] = useState('sourcing');

    // State to control fading
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [currentTab, setCurrentTab] = useState(activeTab); // Keeps track of the currently displayed tab
    const [nextTab, setNextTab] = useState(activeTab); // The next tab that was clicked
  
    useEffect(() => {
      if (currentTab !== nextTab) {
        // Start fading out the content
        setIsFadingOut(true);
  
        // Wait for the fade-out transition (0.5s), then switch the content
        const timer = setTimeout(() => {
          setCurrentTab(nextTab); // Update the current tab after fade out
          setIsFadingOut(false); // Fade back in
        }, 500);
  
        return () => clearTimeout(timer); // Cleanup the timer
      }
    }, [nextTab, currentTab]);
    
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setNextTab(tab); // Set the next tab to trigger the fade-out
  };

  return (
    <Wrapper>
      <SectionMain activeTab={activeTab} setActiveTab={handleTabChange} />

      <SectionTabs currentTab={currentTab} isFadingOut={isFadingOut} />

      <SectionFor />

      <SectionWhy />
    </Wrapper>
  );
};

export default MainPage;
