import styled from "styled-components";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../style";
import { IoDiamondOutline } from "react-icons/io5";
import { TbWorldSearch } from "react-icons/tb";
import { LiaFileContractSolid } from "react-icons/lia";
import { breakpoint, lightGrey } from "../../constants";

const Wrapper = styled.div`
  background-color: ${lightGrey};
  padding: 40px 0;
`;

const Title = styled.h2`
  font-size: 41px;
  text-align: center;
  @media (max-width: ${breakpoint}) {
    font-size: 30px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: stretch;
  gap: 30px;
  flex-direction: row;
  padding-bottom: 40px;
  @media (max-width: ${breakpoint}) {
    flex-direction: column;
  }
`;

const Block = styled.div`
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  line-height: 1.2;
  flex-basis: calc(100% / 3);
  @media (max-width: ${breakpoint}) {
    font-size: 16px;
  }
`;

const Heading = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Text = styled.div``;

const SectionFor = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{t('main.title-features')}</Title>
        <Content>
          <Block>
            <IoDiamondOutline size={50} />
            <Heading>{t("main.feature1.heading")}</Heading>
            <Text>{t("main.feature1.text")}</Text>
          </Block>
          <Block>
            <TbWorldSearch size={50} />
            <Heading>{t("main.feature2.heading")}</Heading>
            <Text>{t("main.feature2.text")}</Text>
          </Block>
          <Block>
            <LiaFileContractSolid size={50} />
            <Heading>{t("main.feature3.heading")}</Heading>
            <Text>{t("main.feature3.text")}</Text>
          </Block>
        </Content>
        <ButtonContainer>
          <Button to="/book-a-demo">{t("main.request-demo")}</Button>
        </ButtonContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionFor;
