import styled from "styled-components";
import { darkGreen, topMargin } from "../../constants";

const Wrapper = styled.div`
  padding: ${topMargin}px 40px 0 40px;
  min-height: calc(100vh - ${topMargin + 30}px);
  background-color: white;
  font-size: 40px;
  color: ${darkGreen};
  text-align: center;
`;

const PageNotFound = () => {
  return (
    <Wrapper>
      <p>Wooops... page not found</p>
      <p>Error 404</p>
    </Wrapper>
  );
};

export default PageNotFound;