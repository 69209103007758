import styled from "styled-components";
import { darkGreen, darkGreenHover } from "../../constants";
import { Link } from "react-router-dom";

const ButtonEl = styled.div<{ $isWhite?: boolean }>`
  background-color: ${(props) => (props.$isWhite ? "white" : darkGreen)};

  font-weight: bold;
  border: none;
  font-size: 16px;
  
  cursor: pointer;
  border-radius: 50px;
  width: fit-content;

  &:hover {
    background-color: ${(props) => (props.$isWhite ? "white" : darkGreenHover)};
  }

  a {
    color: ${(props) => (props.$isWhite ? darkGreen : "white")};
    text-decoration: none;
    padding: 12px 24px;
    display: inline-block;
  }

  @media (min-width: 768px) {
    font-size: 18px;

  }
`;

const ButtonSubmit = styled.button<{ $isWhite?: boolean }>`
  background-color: ${(props) => (props.$isWhite ? "white" : darkGreen)};
  color: ${(props) => (props.$isWhite ? darkGreen : "white")};
  font-weight: bold;
  border: none;
  font-size: 20px;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 50px;
  width: fit-content;

  &:hover {
    background-color: ${(props) => (props.$isWhite ? "white" : darkGreenHover)};
  }

  &:disabled {
    opacity: 0.5;
  }
  @media (min-width: 768px) {
    font-size: 18px;

  }
`;

const Button = (props: {
  to?: string;
  children: any;
  $isWhite?: boolean;
  type?: string;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <>
      {props.type === "submit" ? (
        <ButtonSubmit
          $isWhite={props.$isWhite}
          type={props.type}
          disabled={props.disabled}
          onClick={props.onClick}
        >
          {props.children}
        </ButtonSubmit>
      ) : (
        <ButtonEl $isWhite={props.$isWhite}>
          <Link to={props.to || ""} onClick={props.onClick}>
            {props.children}
          </Link>
        </ButtonEl>
      )}
    </>
  );
};

export default Button;
