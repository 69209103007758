import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../style";
import Button from "../../components/Button";
import { breakpoint, lightGrey } from "../../constants";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const Wrapper = styled.div`
  background-color: ${lightGrey};
`;

const Content = styled.div<{ isFadingOut: boolean }>`
  padding: 40px 0 30px 0;
  display: flex;
  gap: 20px;
  align-items: center;
  animation: ${({ isFadingOut }) => (isFadingOut ? fadeOut : fadeIn)} 0.5s ease;
  opacity: ${({ isFadingOut }) => (isFadingOut ? 0 : 1)};
  transition: opacity 0.5s ease;

  @media (max-width: ${breakpoint}) {
    flex-direction: column;
  }
`;

const Image = styled.div`
  flex-basis: 40%;

  img {
    width: 100%;
  }

  @media (max-width: ${breakpoint}) {
    max-height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Description = styled.div`
  flex-basis: 60%;
`;

const Title = styled.div`
  font-size: 29px;
  font-weight: 600;
`;

const List = styled.ul`
  font-size: 19px;

  li {
    padding-bottom: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: 300px;

  div {
    width: 100%;
    text-align: center;
  }
`;

const Tabs = ({ currentTab, isFadingOut }: { currentTab: string; isFadingOut: boolean }) => {
  const { t } = useTranslation();

  const config = {
    sourcing: {
      title: t('main.tabs.sourcing.title'),
      image: '/assets/tree.jpg',
      list1: t('main.tabs.sourcing.list1'),
      list2: t('main.tabs.sourcing.list2'),
      list3: t('main.tabs.sourcing.list3'),
    },
    procurement: {
      title: t('main.tabs.procurement.title'),
      list1: t('main.tabs.procurement.list1'),
      list2: t('main.tabs.procurement.list2'),
      list3: t('main.tabs.procurement.list3'),
      image: '/assets/truck.jpg'
    },
    "supply-chain": {
      title: t('main.tabs.supply-chain.title'),
      list1: t('main.tabs.supply-chain.list1'),
      list2: t('main.tabs.supply-chain.list2'),
      list3: t('main.tabs.supply-chain.list3'),
      image: '/assets/blue.jpg'
    },
    "contract-management": {
      title: t('main.tabs.contract-management.title'),
      list1: t('main.tabs.contract-management.list1'),
      list2: t('main.tabs.contract-management.list2'),
      list3: t('main.tabs.contract-management.list3'),
      image: '/assets/signature.jpg'
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
      <Content isFadingOut={isFadingOut}>
          <Image>
            <img src={config[currentTab as keyof typeof config].image} alt="" />
          </Image>
          <Description>
            <Title>{config[currentTab as keyof typeof config].title}</Title>
            <List>
              <li>{config[currentTab as keyof typeof config].list1}</li>
              <li>{config[currentTab as keyof typeof config].list2}</li>
              <li>{config[currentTab as keyof typeof config].list3}</li>
            </List>
          </Description>
        </Content>
        <ButtonContainer>
          <Button>LEARN MORE</Button>
        </ButtonContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Tabs;
