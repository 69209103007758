import styled from "styled-components";
import Button from "../../../components/Button";
import {
  ContentWrapper,
  Heading,
  SubHeading,
  Text,
} from "../../style";
import { useTranslation } from "react-i18next";
import { darkGreen } from "../../../constants";

const Wrapper = styled.div`
  background-color: #efefef;

  background-size: cover;
  position: relative;
`;

const Image = styled.div`
  flex-basis: 50%;
  img {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 50px 0;
  display: flex;
  align-items: center;
  color: ${darkGreen};
  div {
    flex-basis: 50%;
  }
`;

const SectionMain = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <div>
            <SubHeading>{t("solutions-for-suppliers.main.subheading")}</SubHeading>
            <Heading>{t("solutions-for-suppliers.main.heading")}</Heading>
            <Text>{t("solutions-for-suppliers.main.text")}</Text>
            <Button to="/book-a-demo">
              {t("solutions-for-suppliers.main.button")}
            </Button>
        </div>
        <Image><img src="/assets/people.jpg" alt="academia"></img></Image>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionMain;
