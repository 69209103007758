import styled from "styled-components";
import SectionMain from "./main";
import { breakpoint, topMargin, topMarginMobile } from "../../../constants";
import SectionDescription from "./description";

const Wrapper = styled.div`
  padding-top: ${topMargin}px;
  @media (max-width: ${breakpoint}) {
    padding-top: ${topMarginMobile}px;
  }
`;

const SolutionsForBrands = () => {
  return (
    <Wrapper>
      <SectionMain />

      <SectionDescription />
    </Wrapper>
  );
};

export default SolutionsForBrands;
