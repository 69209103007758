import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { breakpoint, darkGreen, darkGreenHover, topMargin, topMarginMobile } from "../../constants";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../pages/style";
import { CiGlobe } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";

const HeaderContainer = styled.header<{ isTransparent: boolean }>`
  display: flex;
  align-items: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: ${topMargin}px;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
  border-bottom: 5px solid ${({ isTransparent }) => (isTransparent ? "white" : darkGreen)};
  background-color: ${({ isTransparent }) => (!isTransparent ? "white" : "transparent")};

  transition: background-color 0.3s ease, border-bottom 0.3s ease;

  @media (max-width: ${breakpoint}) {
    height: ${topMarginMobile}px;
  }

  svg {
   color: ${({ isTransparent }) => (isTransparent ? "white" : darkGreen)};
  }
`;

const Logo = styled.div`
  width: 90px;

  img {
    width: 100%;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 50px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  gap: 30px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  position: relative;
`;

const StyledLink = styled(Link)<{isTransparent: boolean}>`
   color: ${({ isTransparent }) => (isTransparent ? "white" : darkGreen)};

  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
`;

const DropdownMenuContainer = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  position: absolute;
  min-width: 200px;
  top: 100%;
  left: 20px;
  box-shadow: 0cqb;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -30px;
    margin-left: calc(100% - 110px);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
`;

const DropdownMenu = styled.ul`
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  list-style: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  li {
    margin-bottom: 10px;
    white-space: nowrap;
  }

  li:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${darkGreen};
    text-decoration: none;
    font-size: 20px;

    &:hover {
      color: ${darkGreenHover};
    }
  }
`;

const Solutions = styled.div`
  font-size: 20px;
  padding-bottom: 10px;
`;

const Hamburger = styled.div<{ isTransparent: boolean}>`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;

  div {
    width: 30px;
    height: 3px;
    background: ${({ isTransparent }) => (isTransparent ? "white" : darkGreen)};

    margin: 3px 0;
    transition: background 0.3s ease;
  }

  @media (max-width: 900px) {
    display: flex;
  }
`;

const Chevron = styled.span<{ isTransparent: boolean }>`
  margin-left: 8px;
  border: ${({ isTransparent }) => (isTransparent ? "solid white" : 'solid ' + darkGreen)};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  margin-bottom: 2px;

`;

const MobileMenu = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: ${topMarginMobile}px;
  right: 0;
  width: 300px;
  height: calc(100%);
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transform: ${({ $isOpen }) =>
  $isOpen ? "translateX(0%)" : "translateX(100%)"};
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`;

const MobileMenuLink = styled(Link)`
  color: ${darkGreen};
  text-decoration: none;
  font-size: 20px;
  margin-bottom: 15px;
  display: block;

  &:hover {
    color: ${darkGreenHover};
  }
`;
const Icons = styled.div`
  display: flex;
  gap: 20px;
  color: ${darkGreen};
`

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t } = useTranslation();

  const [isScrolled, setIsScrolled] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const location = useLocation(); 
  const isMainPage = location.pathname === "/";

  const isTransparent = !isScrolled && isMainPage && !mobileMenuOpen
  return (
    <HeaderContainer isTransparent={isTransparent}>
      <ContentWrapper>
        <NavContainer>
          <Logo>
            <Link to="/" onClick={() => setMobileMenuOpen(false)}>
              { isTransparent
                ? <img src="/assets/logo_w.png" alt="logo" />
                : <img src="/assets/logo.png" alt="logo" />
              }
            </Link>
          </Logo>

          <Nav>
            <NavList>
              <NavItem
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <StyledLink to="#" isTransparent={isTransparent}>{t("navigation.solutions")}</StyledLink>
                <Chevron isTransparent={isTransparent} />
                <DropdownMenuContainer $isVisible={dropdownVisible}>
                  <DropdownMenu>
                    <li>
                      <Link to="/solutions/for-brands">
                        {t("navigation.for-brands")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/for-suppliers">
                        {t("navigation.for-suppliers")}
                      </Link>
                    </li>
                  </DropdownMenu>
                </DropdownMenuContainer>
              </NavItem>
              {/* <NavItem>
                <StyledLink isTransparent={isTransparent} to="/our-company">
                  {t("navigation.our-company")}
                </StyledLink>
              </NavItem> */}
            </NavList>

            <Icons>
              <CiGlobe size={25} />
              <CiUser size={25} />
              <CiSearch size={25} />
            </Icons>

            <Button $isWhite={isTransparent} to="/book-a-demo">{t("book-a-demo")}</Button>
          </Nav>

          <Hamburger isTransparent={isTransparent} onClick={toggleMobileMenu}>
            { mobileMenuOpen
              ? <IoMdCloseCircleOutline size={40} />
              : <><div /><div /><div /></>}
          </Hamburger>
        </NavContainer>

        <MobileMenu $isOpen={mobileMenuOpen}>
          <Solutions>{t("navigation.solutions")}:</Solutions>
          <div style={{ paddingLeft: 20 }}>
            <MobileMenuLink
              to="/solutions/for-brands"
              onClick={() => setMobileMenuOpen(false)}
            >
              {t("navigation.for-brands")}
            </MobileMenuLink>
            <MobileMenuLink
              to="/solutions/for-suppliers"
              onClick={() => setMobileMenuOpen(false)}
            >
              {t("navigation.for-suppliers")}
            </MobileMenuLink>
          </div>
          {/* <MobileMenuLink
            to="/our-company"
            onClick={() => setMobileMenuOpen(false)}
          >
            {t("navigation.our-company")}
          </MobileMenuLink> */}

          <Button to="/book-a-demo" onClick={() => setMobileMenuOpen(false)}>
            {t("book-a-demo")}
          </Button>
        </MobileMenu>
      </ContentWrapper>
    </HeaderContainer>
  );
};

export default Header;
