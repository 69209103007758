
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { ContentWrapper, Heading } from "../../style";
import { Wrapper, Content, Link, Flex, Text, Title, Image, Subtitle, List, ListEl, EndingHeading, EndingSubheading, ButtonContainer } from "../styles";

const SectionDescription = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>{t('solutions-for-brands.description.title')}</Heading>
          <Flex>
            <Image><img src="/assets/tree.jpg" alt="plants"></img></Image>
            <div>
                <Title>{t('solutions-for-brands.description.list1.title')}</Title>
                <Subtitle>{t('solutions-for-brands.description.list1.subtitle')}</Subtitle>
                <Text>{t('solutions-for-brands.description.list1.text')}</Text>
                <List>
                  <ListEl>{t('solutions-for-brands.description.list1.list1')}</ListEl>
                  <ListEl>{t('solutions-for-brands.description.list1.list2')}</ListEl>
                  <ListEl>{t('solutions-for-brands.description.list1.list3')}</ListEl>
                </List>
                <Link><a href="/book-a-demo">{t('solutions-for-brands.description.list1.link')}</a></Link>
            </div>
          </Flex>

          <Flex>
            <Image><img src="/assets/truck.jpg" alt="plants"></img></Image>
            <div>
                <Title>{t('solutions-for-brands.description.list2.title')}</Title>
                <Subtitle>{t('solutions-for-brands.description.list2.subtitle')}</Subtitle>
                <Text>{t('solutions-for-brands.description.list2.text')}</Text>
                <List>
                  <ListEl>{t('solutions-for-brands.description.list2.list1')}</ListEl>
                  <ListEl>{t('solutions-for-brands.description.list2.list2')}</ListEl>
                  <ListEl>{t('solutions-for-brands.description.list2.list3')}</ListEl>
                </List>
                <Link><a href="/book-a-demo">{t('solutions-for-brands.description.list2.link')}</a></Link>
            </div>
          </Flex>

          <Flex>
            <Image><img src="/assets/signature.jpg" alt="plants"></img></Image>
            <div>
                <Title>{t('solutions-for-brands.description.list3.title')}</Title>
                <Subtitle>{t('solutions-for-brands.description.list3.subtitle')}</Subtitle>
                <Text>{t('solutions-for-brands.description.list3.text')}</Text>
                <List>
                  <ListEl>{t('solutions-for-brands.description.list3.list1')}</ListEl>
                  <ListEl>{t('solutions-for-brands.description.list3.list2')}</ListEl>
                  <ListEl>{t('solutions-for-brands.description.list3.list3')}</ListEl>
                </List>
                <Link><a href="/book-a-demo">{t('solutions-for-brands.description.list3.link')}</a></Link>
            </div>
          </Flex>

          <EndingHeading>{t('solutions-for-brands.description.join')}</EndingHeading>
          <EndingSubheading>{t('solutions-for-brands.description.discover')}</EndingSubheading>
          <ButtonContainer>
            <Button to="/compare-plans">{t('solutions-for-brands.description.button')}</Button>
          </ButtonContainer>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionDescription;
