import styled from "styled-components";
import { breakpoint, darkGreen, lightGrey, topMargin, topMarginMobile } from "../../constants";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../style";

const Wrapper = styled.div`
  background-color: ${lightGrey};
  padding-top: ${topMargin}px;
  @media (max-width: ${breakpoint}) {
    padding-top: ${topMarginMobile}px;
  }
`;

const Content = styled.div`
  padding: 30px 0;
`;

const Heading = styled.div`
  font-size: 50px; 
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
  color: ${darkGreen};
  margin-bottom: 20px;
  @media (max-width: ${breakpoint}) {
    font-size: 30px;
  }
`;

const Block = styled.div`
  background-color: white;
  border-radius: 20px;
  border: 2px solid black;
  padding: 20px;
  font-size: 1rem; /* Adjusted font size for smaller screens */
  line-height: 1.5;
  margin-bottom: 20px; /* Added margin for spacing between blocks */

  @media (min-width: 768px) {
    flex-basis: calc(100% / 3);
  }
`;

const BlockHeading = styled.div`
  font-size: 1.25rem; /* Adjusted font size for smaller screens */
  font-weight: bold;
  margin-bottom: 10px; /* Added margin for spacing */
`;

const Price = styled.div`
  font-size: 2.5rem; /* Adjusted font size for smaller screens */
  color: #d6b57d;
  border-bottom: 1px solid black;
  margin-bottom: 10px; /* Added margin for spacing */
`;

const List = styled.ul`
  padding-left: 20px; /* Added padding for list items */
  margin-bottom: 10px; /* Added margin for spacing */
`;

const Text = styled.p`
  margin-bottom: 10px; /* Added margin for spacing */
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  gap: 20px; /* Adjusted gap for mobile view */
  padding: 20px 0; /* Adjusted padding for mobile view */

  @media (min-width: 768px) {
    flex-direction: row; /* Horizontal layout for larger screens */
    gap: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const ComparePlans = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
      <Content>
        <Heading>{t("compare-plans-page.choose-plan")}</Heading>
        <Flex>
          <Block>
            <BlockHeading>{t("compare-plans-page.start")}</BlockHeading>
            <Price>{t("compare-plans-page.price-start")}</Price>
            <Text>{t("compare-plans-page.start-description")}</Text>
            <List>
              {Object.values(
                t("compare-plans-page.start-features", { returnObjects: true }),
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </List>
          </Block>

          <Block>
            <BlockHeading>{t("compare-plans-page.grow")}</BlockHeading>
            <Price>{t("compare-plans-page.price-grow")}</Price>
            <Text>{t("compare-plans-page.grow-description")}</Text>
            <List>
              {Object.values(
                t("compare-plans-page.grow-features", { returnObjects: true }),
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </List>
          </Block>

          <Block>
            <BlockHeading>{t("compare-plans-page.expand")}</BlockHeading>
            <Price>{t("compare-plans-page.price-expand")}</Price>
            <Text>{t("compare-plans-page.expand-description")}</Text>
            <List>
              {Object.values(
                t("compare-plans-page.expand-features", {
                  returnObjects: true,
                }),
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </List>
          </Block>
        </Flex>
        <ButtonContainer>
          <Button to="/book-a-demo">
            {t("compare-plans-page.try-for-free")}
          </Button>
        </ButtonContainer>
      </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ComparePlans;
